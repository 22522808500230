window.Init = class Init {
  constructor(env) {
    this.env = env;
  }

  getApiHost() {
    if (this.env === 'local') {
      return 'http://localhost-cloudservices.redventures.com/stratus/';
    }
    return '/api/';
  }
};
window.hn = new Init(window.stratus.env); /* eslint-disable-line */
